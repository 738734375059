// 订单状态值
export enum ORDER_STATUS_VALUE {
  PENDING = 10,
  SHIPPED = 20,
  CREDIT = 30,
  COMPLETED = 40,
  DELETED = 50,
}

// 是否送货
export const DELIVERS = [
  { text: "自取", value: 2 },
  { text: "送货", value: 1 },
];

// 订单状态
export const ORDER_STATUS = [
  {
    label: "待处理",
    value: ORDER_STATUS_VALUE.PENDING,
    icon: "ClockO",
    tag: "#ff976a",
  },
  {
    label: "已处理",
    value: ORDER_STATUS_VALUE.SHIPPED,
    icon: "CartO",
    tag: "#1989fa",
  },
  {
    label: "赊账",
    value: ORDER_STATUS_VALUE.CREDIT,
    icon: "BalanceO",
    tag: "#ee0a24",
  },
  {
    label: "已完成",
    value: ORDER_STATUS_VALUE.COMPLETED,
    icon: "Completed",
    tag: "#07c160",
  },
  {
    label: "删除订单",
    value: ORDER_STATUS_VALUE.DELETED,
    icon: "Failure",
    tag: "#F2F2F2",
    tagText: "#999999",
  },
];
