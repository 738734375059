interface Config {
  /** 基础url */
  BASE_URL: string;
  /** 文件地址url */
  FILE_URL: string;
  /** 默认头像 */
  DEFAULT_AVATAR_URL: string;
}

/**
 * 开发环境
 */
const devConfig: Config = {
  BASE_URL: "/api",
  FILE_URL: "https://file.sjxlife.com/test",
  DEFAULT_AVATAR_URL:
    "/assets/images/mediaDetail/72a14880-1f72-11ef-884c-575ab7ccccd2.png",
};

/**
 * 生产环境
 */
const prodConfig: Config = {
  BASE_URL: "/api",
  FILE_URL: "https://file.sjxlife.com",
  DEFAULT_AVATAR_URL:
    "/assets/files/e9169df275af42c1966b17c9ab058362/c81b4be464974f96a5805162e20a3d53.png",
};

const config: Config = (function () {
  // 判断当前环境
  const env = process.env.NODE_ENV;
  if (env === "production") {
    return prodConfig;
  }
  return devConfig;
})();

export default config;
