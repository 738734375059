import axios from "./index";
import { IApiResponse } from "../types/index";
import { IOrderListItem } from "../types/order";

/**
 * 查询订单详情
 */
export const findOrderDetail = (data: {
  uId: string;
}): Promise<IApiResponse<IOrderListItem>> => {
  return axios.post("/wxOrder/findFrontOrderDetail", data);
};

/**
 * 查询订单详情
 */
export const selectOrderByCustomer = (data: {
  customer_uId: string;
  status: number;
  order_uId: string;
  shop_uId: string;
}): Promise<IApiResponse<IOrderListItem[]>> => {
  return axios.post("/wxOrder/selectFrontOrderByCustomer", data);
};
