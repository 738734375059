import axios from "axios";
import config from "../config";

// 根据环境设置基础URL
const baseURL = config.BASE_URL;

// 创建axios实例
const instance = axios.create({
  baseURL, // 基础 URL
  timeout: 5000, // 超时时间
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 可以在这里添加 token
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    return response.data as any; // 直接返回需要的数据
  },
  (error) => {
    // 错误处理
    if (error.response) {
      console.error("Error:", error.response.data.message);
    } else {
      console.error("Network Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default instance;
