import React from "react";

// 组件
import { Image, ImagePreview } from "react-vant";

// 样式
import styles from "./index.module.less";

// 类
import { IOrderItemProduct } from "../../../../types/order";

interface IProps {
  /** 商品详情 */
  productInfo: IOrderItemProduct;
  /** 预览图片列表 */
  imageList: string[];
  /** 当前索引 */
  imageIndex: number;
}

const Index: React.FC<IProps> = ({ productInfo, imageList, imageIndex }) => {
  return (
    <div className={styles.container}>
      <div className={styles.pruduct_img}>
        <Image
          width="100%"
          height="100%"
          src={productInfo.product_img}
          fit="contain"
          onClick={() => {
            ImagePreview.open({
              images: imageList,
              startPosition: imageIndex,
            });
          }}
        />
      </div>
      <div className={styles.product_info}>
        {/* 商品信息 */}
        <div className={styles.product_info_t}>
          {/* 商品名称 */}
          <div className={styles.product_info_t_n}> {productInfo.name} </div>
          {/* 商品价格 */}
          <div
            className={`${styles.product_info_t_p} ${styles.product_info_price}`}
          >
            ¥
            {productInfo.edit_price
              ? productInfo.edit_price
              : productInfo.price}
          </div>
        </div>
        {/* 商品改价信息 */}
        {productInfo.edit_price && (
          <div className={styles.product_info_f}>
            <span className={styles.product_info_o}>
              原价¥{productInfo.price}
            </span>
          </div>
        )}
        {/* 商品规格 */}
        <div className={styles.product_info_c}>
          <div className={styles.product_info_c_t}>
            {productInfo.combinationName || ""}
          </div>
          <div className={styles.product_info_c_n}>x{productInfo.num}</div>
        </div>
      </div>
    </div>
  );
};

export default Index;
