/**
 * 去除价格的逗号
 * @param price 价格
 * @returns
 */
export const replacePrice = (price: string) => {
  if (!price || typeof price !== "string") {
    return price;
  }
  return price.replace(/,/g, "");
};
