import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import OrderDetail from "./pages/order-detail/index";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/order-detail" Component={OrderDetail} />
        {/* 如果没有匹配到任何路由，可以设置一个默认页面 */}
        <Route Component={() => <div>404 Not Found</div>} />
      </Routes>
    </Router>
  );
}

export default App;
